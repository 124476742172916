const VIDEO_SELECTOR = ".js-video"
const VIDEO_MEDIA_BUTTON_SELECTOR = ".js-button-video"
const VIDEO_PLAYED_CLASS = "played"

class VideoControl extends HTMLElement {
  constructor () {
    super()
    const videoEl = this.querySelector(VIDEO_SELECTOR)
    const buttonPlayPauseEl = this.querySelector(VIDEO_MEDIA_BUTTON_SELECTOR)

    if (videoEl && buttonPlayPauseEl) {
      this.playVideo(videoEl, buttonPlayPauseEl)
      if (videoEl.paused) {
        buttonPlayPauseEl.classList.remove(VIDEO_PLAYED_CLASS)
      } else {
        buttonPlayPauseEl.classList.add(VIDEO_PLAYED_CLASS)
      }

      this.addButtonClickEvent(videoEl, buttonPlayPauseEl)
      const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
      const supportsTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints;

      if (isSafari && supportsTouch) {
        document.body.addEventListener("touchstart", () => this.playVideoOnLowPower(videoEl, buttonPlayPauseEl), {once: true});
      }
    }
  }

  pauseVideo (video, button)  {
    video.pause()
    button.classList.remove(VIDEO_PLAYED_CLASS)
  }

  playVideo (video, button)  {
    video.play()
    button.classList.add(VIDEO_PLAYED_CLASS)
  }

  addButtonClickEvent(video, button) {
    button.addEventListener("click", (e) => {
      e.preventDefault()

      if (video.paused) {
        this.playVideo(video, button)
      } else {
        this.pauseVideo(video, button)
      }

      button.setAttribute("aria-pressed", "true")

      setTimeout(function (){
        button.setAttribute("aria-pressed", "false")
      }, 500)
    })
  }

  playVideoOnLowPower(video, button) {
    try {
      if (video.paused) {
        this.playVideo(video, button)
      }
    }
    catch(err) {
      console.log(err);
    }
  }
}

window.customElements.define("video-control", VideoControl)